const state = {
  keycloak: null,
  pageCode: null,
  pati: null,
}

const mutations =  {
  SET_KEYCLOAK(state, keycloak) {
    state.keycloak = keycloak
  },
  SET_PAGE_CODE(state, pageCode) {
    state.pageCode = pageCode
  },
  SET_PATI(state, pati) {
    state.pati = pati
  }
}

const getters = {
  keycloak: (state) => state.keycloak,
  pageCode: (state) => state.pageCode,
  pati: (state) => state.pati,
}

const actions = {
  logout({ commit, state }, payload = {}) {
    state.keycloak.logout(payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
