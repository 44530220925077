// =========================================================
// * Vue Material Dashboard Laravel - v1.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard-laravel
// * Copyright Creative Tim (https://www.creative-tim.com) & UPDIVISION (https://www.updivision.com)
//
// * Coded by www.creative-tim.com & www.updivision.com
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import VueRx from "vue-rx";

// Plugins
import App from "./App.vue";
// import Chartist from 'chartist'
import DashboardPlugin from "./material-dashboard";
import Keycloak from "keycloak-js";
import VueLogger from "vuejs-logger";
import axios from "../src/axios/index.js";

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueRx);
Vue.use(VueLogger);

// router & store setup
import store from "./store";
import router from "./router";

/** https://github.com/vuematerial/vue-material/issues/2285 */
import { MdField, MdFile } from "vue-material/dist/components";

import "./registerServiceWorker";

Vue.use(MdField);

Vue.component(
  "MdSelect",
  Vue.options.components.MdSelect.extend({
    methods: {
      isInvalidValue: function isInvalidValue() {
        return this.$el.validity
          ? this.$el.validity.badInput
          : this.$el.querySelector("input").validity.badInput;
      },
    },
  })
);

Vue.component(
  "MdFile",
  Vue.options.components.MdFile.extend({
    methods: {
      isInvalidValue: function isInvalidValue() {
        return this.$el.validity
          ? this.$el.validity.badInput
          : this.$el.querySelector("input").validity.badInput;
      },
    },
  })
);

Vue.component(
  "CmField",
  Vue.options.components.MdField.extend({
    computed: {
      valueLength() {
        if (this.stringValue) {
          let length = 0;
          for (let i = 0; i < this.stringValue.length; i++) {
            // 如果是英文或者数字，length加一
            if (
              this.stringValue.charCodeAt(i) >= 0 &&
              this.stringValue.charCodeAt(i) <= 128
            )
              length++;
            // 如果是中文，length加二
            else length += 2;
          }
          return length;
        }

        return 0;
      },
    },
  })
);

Vue.component(
  "MdAutocomplete",
  Vue.options.components.MdAutocomplete.extend({
    methods: {
      onInput(value) {
        this.$emit("input", value);

        if (!this.mdOpenOnFocus) {
          this.showOptions();
        }

        let match = this.searchTerm.constructor
          .toString()
          .match(/function (\w*)/);

        if (match && match[1].toLowerCase() !== "inputevent") {
          this.$emit("md-changed", this.searchTerm);
        }
      },
    },
  })
);
/* end */

// Function that will be called to refresh authorization
// const refreshAuthLogic = failedRequest => store.dispatch('refreshToken');

// createAuthRefreshInterceptor(axios, refreshAuthLogic);

// global library setup
// Vue.prototype.$Chartist = Chartist

const initOptions = {
  url: "https://auth.whaleniu.cn",
  realm: "pdd",
  clientId: process.env.VUE_APP_KEYClOAK_CLIENT_ID,
  onLoad: "login-required",
  useNonce: false,
};

const keycloak = new Keycloak(initOptions);

keycloak
  .init({ onLoad: initOptions.onLoad })
  .then((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      Vue.$log.info("Authenticated");
      store.commit("app/SET_KEYCLOAK", keycloak);

      // store.state.app.authenticated = true
      // store.state.app.keycloak = keycloak
      axios
        .get(`/shop/pageCode`, {
          httpReferer: document.referrer,
        })
        .then((res) => {
          const code = res.data.response.pageCode;
          store.commit("app/SET_PAGE_CODE", code);
          return PDD_OPEN_init({ code: code });
        })
        .then(() => {
          PDD_OPEN_getPati().then((res) => {
            store.commit("app/SET_PATI", res);
            /* eslint-disable no-new */
            const app = new Vue({
              router: router,
              store: store,
              el: "#app",
              render: (h) => h(App, { props: { keycloak: keycloak } }),
            });

            store.$app = app;
          });
        })
        .catch((e) => {
          Vue.$log.error("error getting page code", e);

          const app = new Vue({
            router: router,
            store: store,
            el: "#app",
            render: (h) => h(App, { props: { keycloak: keycloak } }),
          });

          store.$app = app;
        });
    }

    // Token Refresh
    setInterval(() => {
      keycloak
        .updateToken(70)
        .then((refreshed) => {
          // if (refreshed) {
          //   Vue.$log.info('Token refreshed' + refreshed)
          // } else {
          //   Vue.$log.warn('Token not refreshed, valid for ' +
          //     Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) +
          //     ' seconds')
          // }
        })
        .catch(() => {
          Vue.$log.error("Failed to refresh token");
        });
    }, 6000);
  })
  .catch((e) => {
    Vue.$log.error("Authenticated Failed");
  });
