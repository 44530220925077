import axios from '../../axios/index'

// 运费模版
function getShippingTemplates() {
  return axios
    .get(`/shop/templates`)
    .then((response) => response.data)
}

// 店铺类目
function getShopCategories(parentCategory = 0) {
  if (parentCategory == null) {
    return Promise.resolve([])
  }

  return axios
    .get(`/shop/category/${parentCategory}/categories`)
    .then((response) => response.data)
}

// 获取类目属性
function getCategoryProperties(categoryId) {
  return axios.get(`/shop/category/${categoryId}/properties`)
    .then((response) => response.data)
}

// 店铺设置
function getSettings() {
  return axios
    .get(`/shop/settings`)
    .then((response) => response.data)
}

// 更新店铺设置
function updateSettings(settings) {
  return axios
    .patch(`/shop/settings`, settings)
    .then((response) => response.data)
}

// 抓取产品
function createTask(targetUrl, settings, groups) {
  return axios
    .post(`/histories`, { url: targetUrl, config: settings, groups })
    .then((response) => response.data)
}

// 获取用户担任群组的群
function getMyAdminGroups() {
  return axios
    .get('/groups/my/admin')
    .then((response) => response.data)
}

/**
 * 根据名称获取小组信息
 *
 * 此函数通过axios发送HTTP GET请求到服务器，以根据小组名称获取小组的相关信息
 * 它主要用于在客户端根据特定的小组名称检索并返回小组的详细信息
 *
 * @param {string} code - 小组名称，用于定位服务器上的特定小组
 * @returns {Promise} 返回一个Promise，该Promise解析为小组的信息对象
 */
function getGroupByCode(code) {
  return axios
    .get(`/groups/code/${code}`)
    .then((response) => response.data)
    .catch((error) => {
      if (axios.isAxiosError(error)) {
        if (axios.response?.status === 404) {
          return null
        }
      }
    });
}

// 创建群组
function createGroup(group) {
  return axios
    .post('/groups', group)
    .then((response) => response.data);
}

// 删除群组
function deleteGroup(groupId) {
  return axios
    .delete(`/groups/${groupId}`)
    .then((response) => response.data);
}

// 退出群组
function exitGroup(groupId) {
  return axios
    .delete(`/groups/${groupId}/members:exit`)
    .then((response) => response.data);
}

// 申请入群
function applyGroup(groupId, key) {
  return axios
    .post(`/groups/${groupId}/members`, {key})
    .then((response) => response.data)
}

function getCode(groupId) {
  return axios
    .get(`/groups/${groupId}/code`)
    .then((response) => response.data)
}

function refreshCode(groupId) {
  return axios
    .get(`/groups/${groupId}/code?refresh=true`)
    .then((response) => response.data)
}

// 获取 PageCode
// https://open.pinduoduo.com/application/document/browse?idStr=263992F97736A864
function getPageCode() {
  return axios
    .get(`/shop/page_code`)
    .then((response) => response.data)
}

export default {
  getShippingTemplates,
  getShopCategories,
  getCategoryProperties,
  getSettings,
  updateSettings,
  createTask,
  getGroupByCode,
  createGroup,
  deleteGroup,
  exitGroup,
  applyGroup,
  getMyAdminGroups,
  getCode,
  refreshCode,
  getPageCode,
};
